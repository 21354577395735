import React, {useState} from 'react';
import { graphql, Link } from 'gatsby';
import { get } from "lodash";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SEO from '@components/seo';
import Layout from "@components/Layout";
import AddToCart from '@components/Cart/AddToCart';

const ProductDetailTemplate = React.memo(({ data, pageContext }) => {
  const { shopifyProduct: product } = data;
  // console.log(product);
  const {
    images,
    variants,
  } = product;
  // console.log(images);
  // console.log(variants);
  const firstImage = images[0];
  const firstVariant = variants[0];

  const [activeImage, setActiveImage] = useState(firstImage);
  const [selectedVariant, setSelectedVariant] = useState('');

  return (
    <Layout baseRoute="product" blogCategories={pageContext.blogCategories}>
      <SEO title={`"${product.title}" ${product.productType}`} />
      <div className="productContainer contained">
        <div className="breadcrumb">
          <Link to="/">Home</Link> <span>»</span> <Link to="/shop">Shop</Link>
        </div>
        <h1 className="title">{product.title}</h1>
        <div className="column">
          <GatsbyImage
            alt={product.title}
            image={getImage(activeImage.localFile)}
          />
          <div className="thumbnail-wrapper">
            {images.length > 1 &&
              images.map((image) => (
                <GatsbyImage
                  key={image.id}
                  alt={product.title}
                  className={
                    image.id === activeImage.id
                      ? "active thumbnail"
                      : "thumbnail"
                  }
                  image={getImage(image.localFile)}
                  onClick={() => setActiveImage(image)}
                />
              ))}
          </div>
        </div>
        <div className="column">
          <div
            className="product-description"
            dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
          />
        </div>
        <div className="column" style={{ textAlign: "center" }}>
          {variants.length > 1 && (
            <>
              <select
                name="variant-selection"
                value={selectedVariant}
                onChange={(e) => {
                  setSelectedVariant(e.currentTarget.value);
                  console.log("select change", e.currentTarget.value);
                }}
              >
                <option value={""}>Select an option</option>
                {variants.map((variant) => (
                  <option value={variant.shopifyId} key={variant.shopifyId}>
                    {variant.title} - ${variant.price}
                  </option>
                ))}
              </select>
              <br />
              <AddToCart
                disabled={selectedVariant === ""}
                variantId={selectedVariant}
              />
            </>
          )}
          {variants.length === 1 && (
            <AddToCart disabled={false} variantId={firstVariant.shopifyId} />
          )}
        </div>
      </div>
    </Layout>
  );
});

export default ProductDetailTemplate;

export const query = graphql`
  query ($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      handle
      title
      productType
      description
      descriptionHtml
      availableForSale
      variants {
        id
        shopifyId
        title
        price
      }
      images {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { fit: CONTAIN }
              backgroundColor: "transparent"
              layout: CONSTRAINED
              aspectRatio: 1
            )
          }
        }
      }
    }
  }
`;
